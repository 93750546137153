import { OptionsType, OrderByOptions, SearchableFields } from "../types/adventurerTypes";
import { Traits } from "../types/adventurerTypes";

export const PROJECT_URLS = {
    OPENSEA: {
        FRWC: "https://opensea.io/assets/ethereum/0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42/",
        CC: "https://opensea.io/assets/ethereum/0x5180db8f5c931aae63c74266b211f580155ecac8/",
        LM: "https://opensea.io/assets/ethereum/0xca72fecc4bdb993650654a9881f2be15a7875796/",
    },
    LOOKSRARE: {
        FRWC: "https://looksrare.org/collections/0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42/",
        CC: "https://looksrare.org/collections/0x5180db8f5c931aae63c74266b211f580155ecac8/",
        LM: "https://looksrare.org/collections/0xCA72feCc4BDb993650654A9881F2Be15a7875796/",
    },
};

export const ADVENTURER_SEARCH_FIELDS: { [key: string]: SearchableFields } = {
    TOKEN_ID: "tokenId",
    NAME: "name",
    GLITCH: "glitch",
    ART: "art",
    TRAIT: "traits",
};

export const searchByOptions: OptionsType<SearchableFields>[] = [
    { value: ADVENTURER_SEARCH_FIELDS.TOKEN_ID, label: "Token ID" },
    { value: ADVENTURER_SEARCH_FIELDS.NAME, label: "Name" },
    { value: ADVENTURER_SEARCH_FIELDS.GLITCH, label: "Glitch" },
    { value: ADVENTURER_SEARCH_FIELDS.ART, label: "Art" },
    { value: ADVENTURER_SEARCH_FIELDS.TRAIT, label: "Trait" },
];

export const ORDER_BY: { [key: string]: OrderByOptions } = {
    TOKEN_ID: "tokenId",
    BAGS_TOTAL: "bags_total",
    ART_RARITY: "art_rarity",
    BRAWN: "brawn",
    AGILITY: "agility",
    GUILE: "guile",
    SPIRIT: "spirit",
};

export const ORDER_BY_OPTIONS: OptionsType<OrderByOptions>[] = [
    { value: ORDER_BY.TOKEN_ID, label: "Token ID" },
    { value: ORDER_BY.BRAWN, label: "Brawn" },
    { value: ORDER_BY.AGILITY, label: "Agility" },
    { value: ORDER_BY.GUILE, label: "Guile" },
    { value: ORDER_BY.SPIRIT, label: "Spirit" },
    { value: ORDER_BY.BAGS_TOTAL, label: "BAGS Total" },
    { value: ORDER_BY.ART_RARITY, label: "Art Rarity" },
];

export const GLITCH_OPTIONS: OptionsType<Traits>[] = [
    {
        value: "Glitch: Ascii,Glitch: CGA,Glitch: Graph Paper,Glitch: Terminal,Glitch: Rainbow",
        label: "Glitch: Any",
    },
    { value: "Glitch: Ascii", label: "Glitch: Ascii" },
    { value: "Glitch: CGA", label: "Glitch: CGA" },
    { value: "Glitch: Graph Paper", label: "Glitch: Graph Paper" },
    { value: "Glitch: Terminal", label: "Glitch: Terminal" },
    { value: "Glitch: Rainbow", label: "Glitch: Rainbow" },
];

export const TRAIT_OPTIONS: OptionsType<Traits>[] = [
    { value: "Plot Armor", label: "Plot Armor" },
    { value: "Polymath", label: "Polymath" },
    { value: "Oafish", label: "Oafish" },
    { value: "Troll Blood", label: "Troll Blood" },
    { value: "Sneaky", label: "Sneaky" },
    { value: "Innocent", label: "Innocent" },
    { value: "Chosen One", label: "Chosen One" },
    { value: "Lucky Strike", label: "Lucky Strike" },
    { value: "Escape Artist", label: "Escape Artist" },
    { value: "Fey Touched", label: "Fey Touched" },
    { value: "Jeweler", label: "Jeweler" },
    { value: "Insulated", label: "Insulated" },
    { value: "Safecracker", label: "Safecracker" },
    { value: "Skeptic", label: "Skeptic" },
    { value: "H.P. Hatecraft", label: "H.P. Hatecraft" },
    { value: "Lumberjack", label: "Lumberjack" },
    { value: "Treasure Hunter", label: "Treasure Hunter" },
    { value: "Underdog", label: "Underdog" },
    { value: "Nimble", label: "Nimble" },
    { value: "Locksmith", label: "Locksmith" },
    { value: "Cutpurse", label: "Cutpurse" },
    { value: "Eagle Eye", label: "Eagle Eye" },
    { value: "Fisher", label: "Fisher" },
    { value: "Reliable", label: "Reliable" },
    { value: "Town Watch", label: "Town Watch" },
    { value: "Well-Grounded", label: "Well-Grounded" },
    { value: "Tinkerer", label: "Tinkerer" },
    { value: "Paranoid", label: "Paranoid" },
    { value: "Sludge Grudge", label: "Sludge Grudge" },
    { value: "People Person", label: "People Person" },
    { value: "Stress Eater", label: "Stress Eater" },
    { value: "Silver Spoon", label: "Silver Spoon" },
    { value: "Unpierceable", label: "Unpierceable" },
    { value: "Assassin", label: "Assassin" },
    { value: "Vivacious", label: "Vivacious" },
    { value: "Apprentice", label: "Apprentice" },
    { value: "Fletcher", label: "Fletcher" },
    { value: "Unstoppable", label: "Unstoppable" },
    { value: "Clean Freak", label: "Clean Freak" },
    { value: "Fairy Tale Hater", label: "Fairy Tale Hater" },
    { value: "Rune Reader", label: "Rune Reader" },
    { value: "Spelunker", label: "Spelunker" },
    { value: "Squire", label: "Squire" },
    { value: "Thick Skin", label: "Thick Skin" },
    { value: "Smith's Apprentice", label: "Smith's Apprentice" },
    { value: "Initiate", label: "Initiate" },
    { value: "Deceptive", label: "Deceptive" },
    { value: "Slugger", label: "Slugger" },
    { value: "Intimidating", label: "Intimidating" },
    { value: "Scribe", label: "Scribe" },
    { value: "Pauper", label: "Pauper" },
    { value: "Low Metabolism", label: "Low Metabolism" },
    { value: "Awkward", label: "Awkward" },
    { value: "Necrophobic", label: "Necrophobic" },
    { value: "Procrastinator", label: "Procrastinator" },
    { value: "Realist", label: "Realist" },
    { value: "Physician", label: "Physician" },
    { value: "Goth", label: "Goth" },
    { value: "Hunter", label: "Hunter" },
    { value: "Sanctimonious", label: "Sanctimonious" },
    { value: "Gravedigger", label: "Gravedigger" },
    { value: "Fire Walker", label: "Fire Walker" },
    { value: "Temple Charter", label: "Temple Charter" },
    { value: "Trapper", label: "Trapper" },
    { value: "Gourmand", label: "Gourmand" },
    { value: "Mountaineer", label: "Mountaineer" },
    { value: "Naturalist", label: "Naturalist" },
    { value: "Runs Hot", label: "Runs Hot" },
    { value: "Charming", label: "Charming" },
    { value: "Grizzled Veteran", label: "Grizzled Veteran" },
    { value: "Alchemist", label: "Alchemist" },
    { value: "Focused", label: "Focused" },
    { value: "Greenskeeper", label: "Greenskeeper" },
    { value: "Hardy Stock", label: "Hardy Stock" },
    { value: "Merchant", label: "Merchant" },
    { value: "Scavenger", label: "Scavenger" },
    { value: "Fey Slayer", label: "Fey Slayer" },
    { value: "Strong Metabolism", label: "Strong Metabolism" },
    { value: "Sand Crawler", label: "Sand Crawler" },
    { value: "Big Drinker", label: "Big Drinker" },
    { value: "Northerner", label: "Northerner" },
    { value: "Profligate", label: "Profligate" },
    { value: "Brute", label: "Brute" },
    { value: "Misanthrope", label: "Misanthrope" },
    { value: "Ruins Raider", label: "Ruins Raider" },
    { value: "Icy Veins", label: "Icy Veins" },
    { value: "Librarian", label: "Librarian" },
    { value: "Hell Walker", label: "Hell Walker" },
    { value: "Demonslayer", label: "Demonslayer" },
    { value: "Oblivious", label: "Oblivious" },
    { value: "Glitch Walker", label: "Glitch Walker" },
];

export const FULL_TRAIT_DETAILS = [
    {
        trait: "Thick Skin",
        type: "Combat",
        id: 3,
        desc: "Natural resistance to blade attacks.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Unpierceable",
        type: "Combat",
        id: 4,
        desc: "Natural resistance to piercing attacks.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Brute",
        type: "Combat",
        id: 5,
        desc: "Natural resistance to bludgeon attacks.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Icy Veins",
        type: "Combat",
        id: 6,
        desc: "Natural resistance to fire.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Runs Hot",
        type: "Combat",
        id: 7,
        desc: "Natural resistance to cold.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Strong Metabolism",
        type: "Combat",
        id: 8,
        desc: "Natural resistance to poison.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Well-Grounded",
        type: "Combat",
        id: 9,
        desc: "Natural resistance to electric damage.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Vivacious",
        type: "Combat",
        id: 10,
        desc: "Natural resistance to necrotic damage.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Skeptic",
        type: "Combat",
        id: 11,
        desc: "Natural resistance to arcane & necrotic damage.",
        attribute: "spirit_low",
        set_weapon: "",
        value: "2",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Insulated",
        type: "Combat",
        id: 12,
        desc: "Natural resistance to cold & electric damage.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Nimble",
        type: "Combat",
        id: 13,
        desc: "Innate bonus to dodge.",
        attribute: "agility_notlow",
        set_weapon: "",
        value: "2",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Lucky Strike",
        type: "Combat",
        id: 14,
        desc: "Innate bonus to hit on all attacks.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Eagle Eye",
        type: "Combat",
        id: 15,
        desc: "Critical hit bonus with agility weapons.",
        attribute: "agility_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Slugger",
        type: "Combat",
        id: 16,
        desc: "Critical hit bonus with brawn weapons.",
        attribute: "brawn_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Reliable",
        type: "Combat",
        id: 17,
        desc: "A reliable fighter. Innate high defense, but reduced critical hit chance.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Procrastinator",
        type: "Combat",
        id: 18,
        desc: "Fights best when close to death.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Physician",
        type: "Dungeoneering",
        id: 19,
        desc: "Bonus to all healing effects.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Oblivious",
        type: "Dungeoneering",
        id: 20,
        desc: "Lower chance of trap detection.",
        attribute: "",
        set_weapon: "",
        value: "-1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Escape Artist",
        type: "Dungeoneering",
        id: 21,
        desc: "Always aware of location of stairs to next level.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Trapper",
        type: "Dungeoneering",
        id: 22,
        desc: "Bonus when attempting to disarm traps.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Tinkerer",
        type: "Dungeoneering",
        id: 23,
        desc: "Chance to create a useful potion when disarming a trap.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Big Drinker",
        type: "Dungeoneering",
        id: 24,
        desc: "Drinking any potion also provides a healing effect.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Safecracker",
        type: "Dungeoneering",
        id: 25,
        desc: "Expert at finding hidden compartments. Better gear from chests.",
        attribute: "guile_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Treasure Hunter",
        type: "Dungeoneering",
        id: 26,
        desc: "Chance to find bonus gold and gems.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Gourmand",
        type: "Dungeoneering",
        id: 27,
        desc: "Chance to find bonus food & potions.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Librarian",
        type: "Dungeoneering",
        id: 28,
        desc: "Chance to find bonus scrolls.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Hunter",
        type: "Dweller",
        id: 29,
        desc: "An expert at hunting beasts. Attack bonus vs. beasts.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Gravedigger",
        type: "Dweller",
        id: 30,
        desc: "Special knowledge on those returned from the grave. Attack bonus vs. Undead.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Clean Freak",
        type: "Dweller",
        id: 31,
        desc: "Knows just how to clean up those stubborn stains. Attack bonus vs. Oozes.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Assassin",
        type: "Dweller",
        id: 32,
        desc: "Trained in fighting humans & the human-like. Attack bonus vs. humanoids.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Spelunker",
        type: "Dweller",
        id: 33,
        desc: "Learned of the horrors of the deepest dungeons. Attack bonus vs. Deep Ones.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Fey Slayer",
        type: "Dweller",
        id: 34,
        desc: "Skilled at avoiding the tricks of fey folk. Attack bonus vs. Fey.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Chosen One",
        type: "Dweller",
        id: 35,
        desc: "Student of dwellers of myth. Attack bonus vs. Mythics.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Demonslayer",
        type: "Dweller",
        id: 36,
        desc: "Dedicated to fighting the spawn of the hells. Attack bonus vs. demons.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "People Person",
        type: "Dweller",
        id: 37,
        desc: "Stronger attacks but vulnerable when fighting beasts.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Necrophobic",
        type: "Dweller",
        id: 38,
        desc: "Stronger attacks but vulnerable when fighting undead.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Sludge Grudge",
        type: "Dweller",
        id: 39,
        desc: "Stronger attacks but vulnerable when fighting oozes.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Misanthrope",
        type: "Dweller",
        id: 40,
        desc: "Stronger attacks but vulnerable when fighting humanoids.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "H.P. Hatecraft",
        type: "Dweller",
        id: 41,
        desc: "Stronger attacks but vulnerable when fighting deep ones.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Fairy Tale Hater",
        type: "Dweller",
        id: 42,
        desc: "Stronger attacks but vulnerable when fighting the fey.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Realist",
        type: "Dweller",
        id: 43,
        desc: "Stronger attacks but vulnerable when fighting mythics.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Sanctimonious",
        type: "Dweller",
        id: 44,
        desc: "Stronger attacks but vulnerable when fighting demons.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Polymath",
        type: "Innate",
        id: 45,
        desc: "A skilled student, +1 skill to choose from on level up.",
        attribute: "guile_high",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Stress Eater",
        type: "Innate",
        id: 46,
        desc: "Grows hungry faster, but eating provides additional healing.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Rare",
        exclude_trait: "Low Metabolism",
        force_title: "",
    },
    {
        trait: "Sneaky",
        type: "Innate",
        id: 47,
        desc: "Less likely to be detected by dwellers.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "Oafish",
        force_title: "",
    },
    {
        trait: "Oafish",
        type: "Innate",
        id: 48,
        desc: "Increased defense & immune to knockback, but easier for dwellers to detect.",
        attribute: "agility_low",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "Sneaky",
        force_title: "",
    },
    {
        trait: "Low Metabolism",
        type: "Innate",
        id: 49,
        desc: "Slower to hunger, but less healing from food.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Uncommon",
        exclude_trait: "Stress Eater",
        force_title: "",
    },
    {
        trait: "Paranoid",
        type: "Innate",
        id: 50,
        desc: "Expert at spotting traps, but vulnerable to mental attacks.",
        attribute: "",
        set_weapon: "",
        value: "0",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Hardy Stock",
        type: "Innate",
        id: 51,
        desc: "Full of life and hard to kill. Bonus to health.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Troll Blood",
        type: "Innate",
        id: 52,
        desc: "Faster natural healing but vulnerable to fire.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Focused",
        type: "Innate",
        id: 53,
        desc: "Immune to confusion.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Scavenger",
        type: "Innate",
        id: 54,
        desc: "Chance to find bonus loot on defeated enemies.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Unstoppable",
        type: "Innate",
        id: 55,
        desc: "Resistant to being stunned or held.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Innocent",
        type: "Innate",
        id: 56,
        desc: "Immune to charm. Dwellers more likely to spawn asleep or peaceful.",
        attribute: "guile_low",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Underdog",
        type: "Innate",
        id: 57,
        desc: "Always underestimated by opponents. Increased base dodge.",
        attribute: "brawn_low",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Fey Touched",
        type: "Innate",
        id: 58,
        desc: "Luck bonus, increased gold found.",
        attribute: "",
        gear: "fey_art",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Fire Walker",
        type: "Map Attuned",
        id: 59,
        desc: "Extra health in maps with volcanic biome or walls.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Northerner",
        type: "Map Attuned",
        id: 60,
        desc: "Extra health in maps with ice biome or walls.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Sand Crawler",
        type: "Map Attuned",
        id: 61,
        desc: "Extra health in maps with desert biome or walls.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Goth",
        type: "Map Attuned",
        id: 62,
        desc: "Extra health & defense in graveyard biome maps",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Naturalist",
        type: "Map Attuned",
        id: 63,
        desc: "Extra health in forest biome maps",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Mountaineer",
        type: "Map Attuned",
        id: 64,
        desc: "Extra health in mountain biome maps.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Hell Walker",
        type: "Map Attuned",
        id: 65,
        desc: "Extra health & defense in maps with hell biome or walls.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Greenskeeper",
        type: "Map Attuned",
        id: 66,
        desc: "Extra health in grasslands biome maps",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Ruins Raider",
        type: "Map Attuned",
        id: 67,
        desc: "Extra health & defense in ruins",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Temple Charter",
        type: "Map Attuned",
        id: 68,
        desc: "Extra health & defense in temples.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Rune Reader",
        type: "Map Attuned",
        id: 69,
        desc: "Maps with rune line art are partially revealed to you.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Glitch Walker",
        type: "glitch",
        id: 70,
        desc: "Bonus to health & defense in any glitch maps.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Legendary",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Silver Spoon",
        type: "Starting Gear",
        id: 71,
        desc: "Start with a small fortune in extra gold.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Smith's Apprentice",
        type: "Starting Gear",
        id: 72,
        desc: "Start with a Hammer.",
        attribute: "brawn_notlow",
        set_weapon: "hammer",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Lumberjack",
        type: "Starting Gear",
        id: 73,
        desc: "Start with an Axe.",
        attribute: "brawn_notlow",
        set_weapon: "axe",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Fletcher",
        type: "Starting Gear",
        id: 74,
        desc: "Start with a Bow",
        attribute: "agility_notlow",
        set_weapon: "bow",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Squire",
        type: "Starting Gear",
        id: 75,
        desc: "Start with a Sword.",
        attribute: "brawn_notlow",
        set_weapon: "sword",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Fisher",
        type: "Starting Gear",
        id: 76,
        desc: "Start with a Spear.",
        attribute: "brawn_notlow",
        set_weapon: "spear",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Apprentice",
        type: "Starting Gear",
        id: 77,
        desc: "Start with a Wand.",
        attribute: "spirit_notlow",
        set_weapon: "wand",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Initiate",
        type: "Starting Gear",
        id: 78,
        desc: "Start with a Staff.",
        attribute: "spirit_notlow",
        set_weapon: "staff",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Cutpurse",
        type: "Starting Gear",
        id: 79,
        desc: "Start with a Dagger.",
        attribute: "agility_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Scribe",
        type: "Starting Gear",
        id: 80,
        desc: "Start with a random scroll.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Locksmith",
        type: "Starting Gear",
        id: 81,
        desc: "Start with multiple keys.",
        attribute: "guile_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Alchemist",
        type: "Starting Gear",
        id: 82,
        desc: "Start with a random potion.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Town Watch",
        type: "Starting Gear",
        id: 83,
        desc: "Start with a shield",
        attribute: "",
        set_weapon: "shield",
        value: "1",
        rarity: "Rare",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Jeweler",
        type: "Starting Gear",
        id: 84,
        desc: "Start with a ring or amulet",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Pauper",
        type: "Starting Gear",
        id: 85,
        desc: "Reduced starting gear",
        attribute: "",
        set_weapon: "",
        value: "-1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Merchant",
        type: "Story Events",
        id: 88,
        desc: "An expert haggler, cheaper prices.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "Profligate",
        force_title: "",
    },
    {
        trait: "Profligate",
        type: "Story Events",
        id: 89,
        desc: "Terrible with money, higher prices at shops.",
        attribute: "",
        set_weapon: "",
        value: "-1",
        rarity: "Epic",
        exclude_trait: "Merchant",
        force_title: "",
    },
    {
        trait: "Charming",
        type: "Story Events",
        id: 90,
        desc: "A sweet talker, bonus in story events.",
        attribute: "",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Intimidating",
        type: "Story Events",
        id: 91,
        desc: "A powerful presence, bonus in story events.",
        attribute: "brawn_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Deceptive",
        type: "Story Events",
        id: 92,
        desc: "A skilled liar, bonus in story events.",
        attribute: "guile_notlow",
        set_weapon: "",
        value: "1",
        rarity: "Uncommon",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Grizzled Veteran",
        type: "",
        id: 93,
        desc: "Begin all campaigns at level 2",
        attribute: "total_low",
        set_weapon: "",
        value: "1",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "the Experienced",
    },
    {
        trait: "Glitch: CGA",
        type: "glitch",
        id: 94,
        desc: "Start with a rare weapon.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Glitch: Ascii",
        type: "glitch",
        id: 95,
        desc: "Start with rare armor.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Glitch: Terminal",
        type: "glitch",
        id: 96,
        desc: "Start with a rare ring.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Glitch: Graph Paper",
        type: "glitch",
        id: 97,
        desc: "Start with 3 random scrolls.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Awkward",
        type: "Story Events",
        id: 98,
        desc: "Poor at small talk, penalty in some story events.",
        attribute: "",
        set_weapon: "",
        value: "-1",
        rarity: "Epic",
        exclude_trait: "Charming",
        force_title: "",
    },
    {
        trait: "Glitch: Rainbow",
        type: "glitch",
        id: 99,
        desc: "Random strong positive trait on every run.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
    {
        trait: "Plot Armor",
        type: "unique",
        id: 100,
        desc: "Significant bonus to starting health.",
        attribute: "",
        set_weapon: "",
        value: "2",
        rarity: "Epic",
        exclude_trait: "",
        force_title: "",
    },
];

export const art = [
    {
        name: "naga inquistor cap",
        percentage: 0.011379,
        type: "art_head",
    },
    { type: "art_head", name: "forlorn hood", percentage: 0.007852 },
    { type: "art_head", name: "hair 39", percentage: 0.007624 },
    { type: "art_head", name: "hair 27", percentage: 0.008648 },
    { type: "art_head", name: "delve helm", percentage: 0.00899 },
    { type: "art_head", name: "hair 59", percentage: 0.005007 },
    { type: "art_head", name: "hair 57", percentage: 0.0099 },
    { type: "art_head", name: "hair 29", percentage: 0.008421 },
    {
        type: "art_head",
        name: "standard great helm",
        percentage: 0.018207,
    },
    {
        type: "art_head",
        name: "dragon's guard helm",
        percentage: 0.002731,
    },
    { type: "art_head", name: "fur half helm", percentage: 0.01741 },
    { type: "art_head", name: "hair 31", percentage: 0.007169 },
    { name: "stalker's wrap", percentage: 0.018662, type: "art_head" },
    { name: "mountain toque", percentage: 0.007283, type: "art_head" },
    { type: "art_head", name: "hair 24", percentage: 0.004438 },
    { type: "art_head", name: "hair 19", percentage: 0.00239 },
    {
        name: "sword and moon bonnet",
        percentage: 0.016158,
        type: "art_head",
    },
    { type: "art_head", name: "hair 60", percentage: 0.018434 },
    { name: "bolger's lament", percentage: 0.007055, type: "art_head" },
    { type: "art_head", name: "hair 43", percentage: 0.009558 },
    {
        type: "art_head",
        name: "hood of the feared",
        percentage: 0.007169,
    },
    { type: "art_head", name: "cavalry half helm", percentage: 0.013769 },
    {
        name: "wrapped countenance",
        percentage: 0.003983,
        type: "art_head",
    },
    { name: "footman's cap", percentage: 0.012745, type: "art_head" },
    { type: "art_head", name: "hair 10", percentage: 0.019117 },
    { name: "alchemist's cap", percentage: 0.004779, type: "art_head" },
    { type: "art_head", name: "hair 34", percentage: 0.002845 },
    { type: "art_head", name: "delda's cage", percentage: 0.001252 },
    { name: "rictus visage", percentage: 0.012062, type: "art_head" },
    {
        name: "buckled conjurer hat",
        percentage: 0.015476,
        type: "art_head",
    },
    {
        type: "art_head",
        name: "leather sprangenhelm",
        percentage: 0.018207,
    },
    {
        type: "art_head",
        name: "black iron close helm",
        percentage: 0.001593,
    },
    { type: "art_head", name: "hair 2", percentage: 0.009331 },
    { type: "art_head", name: "hair 21", percentage: 0.002503 },
    { name: "premunir", percentage: 0.014224, type: "art_head" },
    { type: "art_head", name: "half helm horned", percentage: 0.005121 },
    { type: "art_head", name: "hair 38", percentage: 0.004096 },
    { type: "art_head", name: "hair 18", percentage: 0.002162 },
    {
        type: "art_head",
        name: "banded sprangenhelm",
        percentage: 0.012062,
    },
    {
        type: "art_head",
        name: "crowned great helm",
        percentage: 0.005121,
    },
    { type: "art_head", name: "hair 61", percentage: 0.005576 },
    { type: "art_head", name: "hair 58", percentage: 0.009786 },
    { type: "art_head", name: "hair 15", percentage: 0.009558 },
    { name: "channeler's hat", percentage: 0.006372, type: "art_head" },
    { type: "art_head", name: "hair 4", percentage: 0.0099 },
    { type: "art_head", name: "hair 52", percentage: 0.0033 },
    { type: "art_head", name: "hair 47", percentage: 0.001707 },
    {
        type: "art_head",
        name: "steppe's brass helm",
        percentage: 0.018776,
    },
    { name: "emporer mink cap", percentage: 0.019686, type: "art_head" },
    { type: "art_head", name: "hair 26", percentage: 0.011607 },
    { name: "pigface", percentage: 0.002162, type: "art_head" },
    { type: "art_head", name: "hair 40", percentage: 0.011607 },
    { type: "art_head", name: "hair 33", percentage: 0.011948 },
    { type: "art_head", name: "hair 25", percentage: 0.005803 },
    { name: "iron armsmen", percentage: 0.005576, type: "art_head" },
    { name: "horned defender", percentage: 0.011948, type: "art_head" },
    {
        name: "stout frog's mouth",
        percentage: 0.004324,
        type: "art_head",
    },
    { type: "art_head", name: "hair 7", percentage: 0.004552 },
    { name: "rusted crown", percentage: 0.019003, type: "art_head" },
    { type: "art_head", name: "hair 16", percentage: 0.005917 },
    { name: "studded mask", percentage: 0.016727, type: "art_head" },
    { name: "ragged cloak", percentage: 0.014452, type: "art_head" },
    { type: "art_head", name: "hair 5", percentage: 0.009672 },
    { type: "art_head", name: "hair 12", percentage: 0.008307 },
    { type: "art_head", name: "hair 23", percentage: 0.004552 },
    { type: "art_head", name: "hair 41", percentage: 0.004665 },
    { type: "art_head", name: "hair 54", percentage: 0.004665 },
    { type: "art_head", name: "hair 56", percentage: 0.009445 },
    { type: "art_head", name: "traveller's hood", percentage: 0.006941 },
    { type: "art_head", name: "hair 17", percentage: 0.005462 },
    { type: "art_head", name: "hair 32", percentage: 0.011493 },
    { type: "art_head", name: "hair 14", percentage: 0.009558 },
    { name: "leather ushunka", percentage: 0.018434, type: "art_head" },
    { type: "art_head", name: "steel great helm", percentage: 0.017751 },
    { type: "art_head", name: "hair 30", percentage: 0.007852 },
    { type: "art_head", name: "hair 6", percentage: 0.00421 },
    { name: "wiz hat common", percentage: 0.020369, type: "art_head" },
    { type: "art_head", name: "hair 3", percentage: 0.008762 },
    { type: "art_head", name: "hair 37", percentage: 0.006031 },
    { type: "art_head", name: "hair 11", percentage: 0.009331 },
    {
        type: "art_head",
        name: "skull & rose half helm",
        percentage: 0.008534,
    },
    { type: "art_head", name: "hair 9", percentage: 0.005348 },
    { type: "art_head", name: "hair 22", percentage: 0.004779 },
    { type: "art_head", name: "normal nasal helm", percentage: 0.011265 },
    { type: "art_head", name: "naga aswaran helm", percentage: 0.018093 },
    { name: "occult bonnet", percentage: 0.007965, type: "art_head" },
    { type: "art_head", name: "hair 48", percentage: 0.00421 },
    { type: "art_head", name: "hair 1", percentage: 0.009217 },
    { name: "full metal armet", percentage: 0.00239, type: "art_head" },
    { name: "bronze sallet", percentage: 0.001593, type: "art_head" },
    {
        type: "art_head",
        name: "unbreachable great helm",
        percentage: 0.017638,
    },
    { type: "art_head", name: "hair 53", percentage: 0.002276 },
    { type: "art_head", name: "kettle head helm", percentage: 0.002731 },
    { type: "art_head", name: "game's great helm", percentage: 0.009331 },
    { type: "art_head", name: "hair 42", percentage: 0.008762 },
    { type: "art_head", name: "hair 46", percentage: 0.002276 },
    { name: "dandy's favor", percentage: 0.008307, type: "art_head" },
    { type: "art_head", name: "hair 44", percentage: 0.002276 },
    { name: "fine magus cone", percentage: 0.012289, type: "art_head" },
    { type: "art_head", name: "hair 45", percentage: 0.004324 },
    { type: "art_head", name: "hair 50", percentage: 0.002731 },
    { type: "art_head", name: "hair 28", percentage: 0.004893 },
    { name: "hedge topper", percentage: 0.018662, type: "art_head" },
    { type: "art_head", name: "hair 8", percentage: 0.009786 },
    { name: "bone crown", percentage: 0.017979, type: "art_head" },
    {
        name: "clipped light armet",
        percentage: 0.003414,
        type: "art_head",
    },
    { type: "art_head", name: "hair 36", percentage: 0.006259 },
    { type: "art_head", name: "hair 20", percentage: 0.006486 },
    { type: "art_head", name: "hair 35", percentage: 0.009217 },
    { type: "art_head", name: "hair 49", percentage: 0.001934 },
    { type: "art_head", name: "the dummy helm", percentage: 0.007965 },
    { name: "old god's crown", percentage: 0.01741, type: "art_head" },
    { type: "art_head", name: "hair 13", percentage: 0.005803 },
    { name: "temple mage garb", percentage: 0.022645, type: "art_armor" },
    {
        name: "occultist's charm robe",
        percentage: 0.021734,
        type: "art_armor",
    },
    {
        name: "defender's blessed plate",
        percentage: 0.020482,
        type: "art_armor",
    },
    {
        name: "rizellda's formal",
        percentage: 0.006031,
        type: "art_armor",
    },
    {
        name: "victor's lush fur armor",
        percentage: 0.022645,
        type: "art_armor",
    },
    { name: "scryer's robe", percentage: 0.023555, type: "art_armor" },
    {
        name: "rogue's full leathers",
        percentage: 0.024351,
        type: "art_armor",
    },
    {
        name: "plain's hide and horn armor",
        percentage: 0.010469,
        type: "art_armor",
    },
    {
        name: "enchanted mezzmer",
        percentage: 0.004438,
        type: "art_armor",
    },
    {
        name: "studded & quilted leather",
        percentage: 0.022986,
        type: "art_armor",
    },
    { name: "champion's plate", percentage: 0.018434, type: "art_armor" },
    {
        name: "rogue's leather vest",
        percentage: 0.023213,
        type: "art_armor",
    },
    {
        name: "clasped leather vest",
        percentage: 0.018093,
        type: "art_armor",
    },
    {
        name: "adventurer's chain mail",
        percentage: 0.022417,
        type: "art_armor",
    },
    { name: "raider's armor", percentage: 0.023782, type: "art_armor" },
    { name: "naga guard coat", percentage: 0.020824, type: "art_armor" },
    {
        name: "tough boiled leather",
        percentage: 0.023896,
        type: "art_armor",
    },
    {
        name: "simple farmer armor",
        percentage: 0.024238,
        type: "art_armor",
    },
    { name: "collared plate", percentage: 0.020141, type: "art_armor" },
    { name: "acolyte robe", percentage: 0.02401, type: "art_armor" },
    {
        name: "hedge wizard outfit",
        percentage: 0.024238,
        type: "art_armor",
    },
    {
        name: "stealth quilted enhanced",
        percentage: 0.022417,
        type: "art_armor",
    },
    {
        name: "charlatan's outfit",
        percentage: 0.024351,
        type: "art_armor",
    },
    { name: "steel plate", percentage: 0.02162, type: "art_armor" },
    {
        name: "naga priest's robe",
        percentage: 0.022189,
        type: "art_armor",
    },
    {
        name: "naga healer's garb",
        percentage: 0.012403,
        type: "art_armor",
    },
    {
        name: "humble servant robe",
        percentage: 0.023555,
        type: "art_armor",
    },
    {
        name: "apprentice's ambition",
        percentage: 0.021734,
        type: "art_armor",
    },
    { name: "warlock's robes", percentage: 0.023327, type: "art_armor" },
    { name: "stealth quilted", percentage: 0.024351, type: "art_armor" },
    {
        name: "quam quilted and leather",
        percentage: 0.025376,
        type: "art_armor",
    },
    { name: "iron tower plate", percentage: 0.0231, type: "art_armor" },
    { name: "merchant's getup", percentage: 0.021848, type: "art_armor" },
    {
        name: "temporal subjegator's trappings",
        percentage: 0.021051,
        type: "art_armor",
    },
    {
        name: "royal eagle armor",
        percentage: 0.019231,
        type: "art_armor",
    },
    { name: "explorer's wrap", percentage: 0.022645, type: "art_armor" },
    {
        name: "keen's adventuring garb",
        percentage: 0.022303,
        type: "art_armor",
    },
    { name: "wizard's robes", percentage: 0.024238, type: "art_armor" },
    { name: "worn threads", percentage: 0.02401, type: "art_armor" },
    { name: "common barbarian", percentage: 0.022076, type: "art_armor" },
    { name: "fine magus robe", percentage: 0.022986, type: "art_armor" },
    {
        name: "king's guard chain mail",
        percentage: 0.021962,
        type: "art_armor",
    },
    { name: "bravo's plate", percentage: 0.012517, type: "art_armor" },
    {
        name: "fighter full leathers",
        percentage: 0.023327,
        type: "art_armor",
    },
    {
        name: "ruler's fur armor",
        percentage: 0.019914,
        type: "art_armor",
    },
    {
        name: "skull and roses chain",
        percentage: 0.011152,
        type: "art_armor",
    },
    {
        name: "black steel plate",
        percentage: 0.021848,
        type: "art_armor",
    },
    { name: "collared mezzmer", percentage: 0.008193, type: "art_armor" },
    { name: "naga skin robe", percentage: 0.012517, type: "art_armor" },
    { name: "sword of honor", percentage: 0.020824, type: "art_weapon" },
    { name: "gilded blade", percentage: 0.013541, type: "art_weapon" },
    { name: "long bow", percentage: 0.022645, type: "art_weapon" },
    { name: "battle axe", percentage: 0.020938, type: "art_weapon" },
    { name: "stone axe", percentage: 0.016955, type: "art_weapon" },
    { name: "runic bow", percentage: 0.013541, type: "art_weapon" },
    { name: "pole", percentage: 0.016841, type: "art_weapon" },
    {
        name: "regal long sword",
        percentage: 0.012631,
        type: "art_weapon",
    },
    { name: "ornate bow", percentage: 0.0231, type: "art_weapon" },
    { name: "footman's gift", percentage: 0.023782, type: "art_weapon" },
    { name: "morning glory", percentage: 0.017069, type: "art_weapon" },
    { name: "block mace", percentage: 0.019686, type: "art_weapon" },
    { name: "shamanic staff", percentage: 0.011493, type: "art_weapon" },
    { name: "trident", percentage: 0.019686, type: "art_weapon" },
    { name: "steel sword", percentage: 0.025034, type: "art_weapon" },
    { name: "crescent pole", percentage: 0.018093, type: "art_weapon" },
    {
        name: "dawnbringer sword",
        percentage: 0.024238,
        type: "art_weapon",
    },
    { name: "spiked club", percentage: 0.018662, type: "art_weapon" },
    { name: "viking axe", percentage: 0.019458, type: "art_weapon" },
    {
        name: "zorach warrior sword",
        percentage: 0.021734,
        type: "art_weapon",
    },
    { name: "thumper", percentage: 0.020938, type: "art_weapon" },
    {
        name: "isles broad sword",
        percentage: 0.023213,
        type: "art_weapon",
    },
    { name: "woodsman's axe", percentage: 0.019231, type: "art_weapon" },
    {
        name: "ornate bastard sword",
        percentage: 0.023669,
        type: "art_weapon",
    },
    {
        name: "dark bargains wand",
        percentage: 0.012631,
        type: "art_weapon",
    },
    { name: "recurve bow", percentage: 0.021165, type: "art_weapon" },
    { name: "iron sword", percentage: 0.023782, type: "art_weapon" },
    { name: "crafted stabber", percentage: 0.024238, type: "art_weapon" },
    { name: "star mace", percentage: 0.017979, type: "art_weapon" },
    { name: "leaf spear", percentage: 0.020824, type: "art_weapon" },
    { name: "wooden staff", percentage: 0.023327, type: "art_weapon" },
    { name: "glower's staff", percentage: 0.023213, type: "art_weapon" },
    {
        name: "iron short sword",
        percentage: 0.022189,
        type: "art_weapon",
    },
    { name: "bo stick", percentage: 0.01741, type: "art_weapon" },
    { name: "iron bastard", percentage: 0.023896, type: "art_weapon" },
    { name: "battle hammer", percentage: 0.021393, type: "art_weapon" },
    { name: "crystal wand", percentage: 0.011265, type: "art_weapon" },
    {
        name: "staff of spheres",
        percentage: 0.021962,
        type: "art_weapon",
    },
    { name: "glaive", percentage: 0.023441, type: "art_weapon" },
    { name: "gladius", percentage: 0.025262, type: "art_weapon" },
    {
        name: "greater staff of spheres",
        percentage: 0.012062,
        type: "art_weapon",
    },
    { name: "spiked mace", percentage: 0.019914, type: "art_weapon" },
    { name: "wand of curses", percentage: 0.020938, type: "art_weapon" },
    { name: "ornate hammer", percentage: 0.02162, type: "art_weapon" },
    { name: "culling scythe", percentage: 0.01832, type: "art_weapon" },
    { name: "ritual axe", percentage: 0.020369, type: "art_weapon" },
    { name: "ornate spear", percentage: 0.022303, type: "art_weapon" },
    {
        name: "legion long sword",
        percentage: 0.021051,
        type: "art_weapon",
    },
    {
        name: "wand of the claw",
        percentage: 0.013086,
        type: "art_weapon",
    },
    { name: "perfect katana", percentage: 0.022076, type: "art_weapon" },
    {
        name: "diamond heater shield",
        percentage: 0.017751,
        type: "art_shield",
    },
    { name: "legend shield", percentage: 0.009331, type: "art_shield" },
    { name: "wooden shield", percentage: 0.017865, type: "art_shield" },
    { name: "dragon shield", percentage: 0.006259, type: "art_shield" },
    { name: "lion targe", percentage: 0.017751, type: "art_shield" },
    { name: "augur targe", percentage: 0.009672, type: "art_shield" },
    { name: "knight's shield", percentage: 0.0165, type: "art_shield" },
    {
        name: "weathered shield",
        percentage: 0.015931,
        type: "art_shield",
    },
    { name: "hunter's eye", percentage: 0.011721, type: "art_special" },
    {
        name: "eyes of the dramock",
        percentage: 0.005917,
        type: "art_special",
    },
    { name: "sword scar", percentage: 0.006941, type: "art_special" },
    { name: "tear scar", percentage: 0.002503, type: "art_special" },
    {
        name: "eyes of the witness",
        percentage: 0.010696,
        type: "art_special",
    },
    { name: "dividing scar", percentage: 0.005462, type: "art_special" },
    { name: "warding runes", percentage: 0.006714, type: "art_special" },
    { name: "snarling scar", percentage: 0.003983, type: "art_special" },
    { name: "dagger scar", percentage: 0.006145, type: "art_special" },
    { name: "painted mask", percentage: 0.00569, type: "art_special" },
    { name: "stalker's eye", percentage: 0.003414, type: "art_special" },
    {
        name: "eyes of the fey",
        percentage: 0.005803,
        type: "art_special",
    },
    { type: "art_special", name: "unique", percentage: 0.001138 },
    { name: "runic vision", percentage: 0.014452, type: "art_special" },
    { name: "sign of sorrow", percentage: 0.005234, type: "art_special" },
    { name: "talon scar", percentage: 0.006259, type: "art_special" },
    { name: "runic wards", percentage: 0.002617, type: "art_special" },
    { name: "mark of fury", percentage: 0.011379, type: "art_special" },
    {
        name: "eye of the deep",
        percentage: 0.002845,
        type: "art_special",
    },
    { name: "tally tattoo", percentage: 0.004438, type: "art_special" },
    { name: "spear scar", percentage: 0.005462, type: "art_special" },
    { name: "runic scar", percentage: 0.002617, type: "art_special" },
    {
        name: "beast claw scar",
        percentage: 0.005803,
        type: "art_special",
    },
    { name: "bedroll", percentage: 0.014907, type: "art_gear" },
    {
        name: "bountiful backpack",
        percentage: 0.016727,
        type: "art_gear",
    },
    { name: "ornate quiver", percentage: 0.014907, type: "art_gear" },
    {
        name: "climber's backpack",
        percentage: 0.014793,
        type: "art_gear",
    },
    {
        name: "treasure hunter's backpack",
        percentage: 0.007283,
        type: "art_gear",
    },
];

export const TRAIT_RARITY = [
    {
        name: "Plot Armor",
        percentage: 0.001138,
    },
    {
        name: "Polymath",
        percentage: 0.003072,
    },
    {
        name: "Glitch: Ascii",
        percentage: 0.006145,
    },
    {
        name: "Oafish",
        percentage: 0.007396,
    },
    {
        name: "Troll Blood",
        percentage: 0.007738,
    },
    {
        name: "Sneaky",
        percentage: 0.007852,
    },
    {
        name: "Innocent",
        percentage: 0.008079,
    },
    {
        name: "Chosen One",
        percentage: 0.008079,
    },
    {
        name: "Lucky Strike",
        percentage: 0.008534,
    },
    {
        name: "Escape Artist",
        percentage: 0.008534,
    },
    {
        name: "Fey Touched",
        percentage: 0.008762,
    },
    {
        name: "Jeweler",
        percentage: 0.008876,
    },
    {
        name: "Insulated",
        percentage: 0.00899,
    },
    {
        name: "Safecracker",
        percentage: 0.009103,
    },
    {
        name: "Skeptic",
        percentage: 0.009331,
    },
    {
        name: "H.P. Hatecraft",
        percentage: 0.009331,
    },
    {
        name: "Lumberjack",
        percentage: 0.009672,
    },
    {
        name: "Treasure Hunter",
        percentage: 0.009786,
    },
    {
        name: "Underdog",
        percentage: 0.0099,
    },
    {
        name: "Nimble",
        percentage: 0.010241,
    },
    {
        name: "Locksmith",
        percentage: 0.010355,
    },
    {
        name: "Glitch: CGA",
        percentage: 0.010696,
    },
    {
        name: "Cutpurse",
        percentage: 0.010696,
    },
    {
        name: "Eagle Eye",
        percentage: 0.01081,
    },
    {
        name: "Glitch: Graph Paper",
        percentage: 0.011493,
    },
    {
        name: "Fisher",
        percentage: 0.011607,
    },
    {
        name: "Glitch: Terminal",
        percentage: 0.012062,
    },
    {
        name: "Glitch: Rainbow",
        percentage: 0.012403,
    },
    {
        name: "Reliable",
        percentage: 0.013541,
    },
    {
        name: "Town Watch",
        percentage: 0.014907,
    },
    {
        name: "Well-Grounded",
        percentage: 0.015589,
    },
    {
        name: "Tinkerer",
        percentage: 0.016158,
    },
    {
        name: "Paranoid",
        percentage: 0.016272,
    },
    {
        name: "Sludge Grudge",
        percentage: 0.016955,
    },
    {
        name: "People Person",
        percentage: 0.017069,
    },
    {
        name: "Stress Eater",
        percentage: 0.01741,
    },
    {
        name: "Silver Spoon",
        percentage: 0.017751,
    },
    {
        name: "Unpierceable",
        percentage: 0.017979,
    },
    {
        name: "Assassin",
        percentage: 0.017979,
    },
    {
        name: "Vivacious",
        percentage: 0.018548,
    },
    {
        name: "Apprentice",
        percentage: 0.018548,
    },
    {
        name: "Fletcher",
        percentage: 0.018662,
    },
    {
        name: "Unstoppable",
        percentage: 0.018776,
    },
    {
        name: "Clean Freak",
        percentage: 0.019003,
    },
    {
        name: "Fairy Tale Hater",
        percentage: 0.019572,
    },
    {
        name: "Rune Reader",
        percentage: 0.019572,
    },
    {
        name: "Spelunker",
        percentage: 0.019914,
    },
    {
        name: "Squire",
        percentage: 0.020141,
    },
    {
        name: "Thick Skin",
        percentage: 0.02071,
    },
    {
        name: "Smith's Apprentice",
        percentage: 0.020824,
    },
    {
        name: "Initiate",
        percentage: 0.021279,
    },
    {
        name: "Deceptive",
        percentage: 0.02162,
    },
    {
        name: "Slugger",
        percentage: 0.021962,
    },
    {
        name: "Intimidating",
        percentage: 0.022986,
    },
    {
        name: "Scribe",
        percentage: 0.028562,
    },
    {
        name: "Pauper",
        percentage: 0.028903,
    },
    {
        name: "Low Metabolism",
        percentage: 0.030155,
    },
    {
        name: "Awkward",
        percentage: 0.030269,
    },
    {
        name: "Necrophobic",
        percentage: 0.030382,
    },
    {
        name: "Procrastinator",
        percentage: 0.030838,
    },
    {
        name: "Realist",
        percentage: 0.031065,
    },
    {
        name: "Physician",
        percentage: 0.03152,
    },
    {
        name: "Goth",
        percentage: 0.031634,
    },
    {
        name: "Hunter",
        percentage: 0.031975,
    },
    {
        name: "Sanctimonious",
        percentage: 0.032089,
    },
    {
        name: "Gravedigger",
        percentage: 0.032089,
    },
    {
        name: "Fire Walker",
        percentage: 0.032772,
    },
    {
        name: "Temple Charter",
        percentage: 0.032886,
    },
    {
        name: "Trapper",
        percentage: 0.033,
    },
    {
        name: "Gourmand",
        percentage: 0.033,
    },
    {
        name: "Mountaineer",
        percentage: 0.033,
    },
    {
        name: "Naturalist",
        percentage: 0.033113,
    },
    {
        name: "Runs Hot",
        percentage: 0.033113,
    },
    {
        name: "Charming",
        percentage: 0.033113,
    },
    {
        name: "Grizzled Veteran",
        percentage: 0.033341,
    },
    {
        name: "Alchemist",
        percentage: 0.033682,
    },
    {
        name: "Focused",
        percentage: 0.033682,
    },
    {
        name: "Greenskeeper",
        percentage: 0.034137,
    },
    {
        name: "Hardy Stock",
        percentage: 0.034137,
    },
    {
        name: "Merchant",
        percentage: 0.034137,
    },
    {
        name: "Scavenger",
        percentage: 0.034251,
    },
    {
        name: "Fey Slayer",
        percentage: 0.034706,
    },
    {
        name: "Strong Metabolism",
        percentage: 0.03482,
    },
    {
        name: "Sand Crawler",
        percentage: 0.03482,
    },
    {
        name: "Big Drinker",
        percentage: 0.035048,
    },
    {
        name: "Northerner",
        percentage: 0.035162,
    },
    {
        name: "Profligate",
        percentage: 0.035162,
    },
    {
        name: "Brute",
        percentage: 0.035275,
    },
    {
        name: "Misanthrope",
        percentage: 0.035275,
    },
    {
        name: "Ruins Raider",
        percentage: 0.035958,
    },
    {
        name: "Icy Veins",
        percentage: 0.036186,
    },
    {
        name: "Librarian",
        percentage: 0.036299,
    },
    {
        name: "Hell Walker",
        percentage: 0.036641,
    },
    {
        name: "Demonslayer",
        percentage: 0.036755,
    },
    {
        name: "Oblivious",
        percentage: 0.038006,
    },
    {
        name: "Glitch Walker",
        percentage: 0.052799,
    },
];

export const BAGS_RARITY = {
    brawn: [
        {
            value: 4,
            percentage: 0.0007965407,
            percentile: 0,
        },
        {
            value: 5,
            percentage: 0.0031861629,
            percentile: 0.0007965407,
        },
        {
            value: 6,
            percentage: 0.0050068275,
            percentile: 0.0039827036,
        },
        {
            value: 7,
            percentage: 0.0110377788,
            percentile: 0.0089895311,
        },
        {
            value: 8,
            percentage: 0.0639508421,
            percentile: 0.0200273099,
        },
        {
            value: 9,
            percentage: 0.0508648157,
            percentile: 0.083978152,
        },
        {
            value: 10,
            percentage: 0.0604233045,
            percentile: 0.1348429677,
        },
        {
            value: 11,
            percentage: 0.0728265817,
            percentile: 0.1952662722,
        },
        {
            value: 12,
            percentage: 0.0861401912,
            percentile: 0.2680928539,
        },
        {
            value: 13,
            percentage: 0.1075329995,
            percentile: 0.3542330451,
        },
        {
            value: 14,
            percentage: 0.1206190259,
            percentile: 0.4617660446,
        },
        {
            value: 15,
            percentage: 0.1028675467,
            percentile: 0.5823850705,
        },
        {
            value: 16,
            percentage: 0.1547564861,
            percentile: 0.6852526172,
        },
        {
            value: 17,
            percentage: 0.1214155667,
            percentile: 0.8400091033,
        },
        {
            value: 18,
            percentage: 0.03857533,
            percentile: 0.96142467,
        },
    ],
    agility: [
        {
            value: 3,
            percentage: 0.0001137915,
            percentile: 0,
        },
        {
            value: 4,
            percentage: 0.0006827492,
            percentile: 0.0001137915,
        },
        {
            value: 5,
            percentage: 0.0013654984,
            percentile: 0.0007965407,
        },
        {
            value: 6,
            percentage: 0.0037551206,
            percentile: 0.0021620391,
        },
        {
            value: 7,
            percentage: 0.0103550296,
            percentile: 0.0059171597,
        },
        {
            value: 8,
            percentage: 0.0753299954,
            percentile: 0.0162721893,
        },
        {
            value: 9,
            percentage: 0.0477924442,
            percentile: 0.0916021847,
        },
        {
            value: 10,
            percentage: 0.0580336823,
            percentile: 0.1393946289,
        },
        {
            value: 11,
            percentage: 0.0738507055,
            percentile: 0.1974283112,
        },
        {
            value: 12,
            percentage: 0.0935366409,
            percentile: 0.2712790167,
        },
        {
            value: 13,
            percentage: 0.1209604005,
            percentile: 0.3648156576,
        },
        {
            value: 14,
            percentage: 0.1165225307,
            percentile: 0.4857760581,
        },
        {
            value: 15,
            percentage: 0.0970641784,
            percentile: 0.6022985888,
        },
        {
            value: 16,
            percentage: 0.141101502,
            percentile: 0.6993627672,
        },
        {
            value: 17,
            percentage: 0.1261948111,
            percentile: 0.8404642692,
        },
        {
            value: 18,
            percentage: 0.0333409194,
            percentile: 0.9666590803,
        },
    ],
    guile: [
        {
            value: 3,
            percentage: 0.0001137915,
            percentile: 0,
        },
        {
            value: 4,
            percentage: 0.0007965407,
            percentile: 0.0001137915,
        },
        {
            value: 5,
            percentage: 0.0018206645,
            percentile: 0.0009103322,
        },
        {
            value: 6,
            percentage: 0.0055757852,
            percentile: 0.0027309967,
        },
        {
            value: 7,
            percentage: 0.010013655,
            percentile: 0.0083067819,
        },
        {
            value: 8,
            percentage: 0.0681611288,
            percentile: 0.0183204369,
        },
        {
            value: 9,
            percentage: 0.046882112,
            percentile: 0.0864815657,
        },
        {
            value: 10,
            percentage: 0.0584888484,
            percentile: 0.1333636777,
        },
        {
            value: 11,
            percentage: 0.0761265362,
            percentile: 0.1918525261,
        },
        {
            value: 12,
            percentage: 0.090578061,
            percentile: 0.2679790623,
        },
        {
            value: 13,
            percentage: 0.1131087847,
            percentile: 0.3585571233,
        },
        {
            value: 14,
            percentage: 0.1145880746,
            percentile: 0.471665908,
        },
        {
            value: 15,
            percentage: 0.1026399636,
            percentile: 0.5862539826,
        },
        {
            value: 16,
            percentage: 0.1431497497,
            percentile: 0.6888939462,
        },
        {
            value: 17,
            percentage: 0.1307464725,
            percentile: 0.8320436959,
        },
        {
            value: 18,
            percentage: 0.0372098316,
            percentile: 0.9627901684,
        },
    ],
    spirit: [
        {
            value: 3,
            percentage: 0.0001137915,
            percentile: 0,
        },
        {
            value: 4,
            percentage: 0.0010241238,
            percentile: 0.0001137915,
        },
        {
            value: 5,
            percentage: 0.0021620391,
            percentile: 0.0011379153,
        },
        {
            value: 6,
            percentage: 0.0045516614,
            percentile: 0.0032999544,
        },
        {
            value: 7,
            percentage: 0.0124032772,
            percentile: 0.0078516158,
        },
        {
            value: 8,
            percentage: 0.0698680018,
            percentile: 0.020254893,
        },
        {
            value: 9,
            percentage: 0.0508648157,
            percentile: 0.0901228948,
        },
        {
            value: 10,
            percentage: 0.0583750569,
            percentile: 0.1409877105,
        },
        {
            value: 11,
            percentage: 0.0784023669,
            percentile: 0.1993627674,
        },
        {
            value: 12,
            percentage: 0.0895539372,
            percentile: 0.2777651343,
        },
        {
            value: 13,
            percentage: 0.109126081,
            percentile: 0.3673190715,
        },
        {
            value: 14,
            percentage: 0.1109467456,
            percentile: 0.4764451525,
        },
        {
            value: 15,
            percentage: 0.0997951752,
            percentile: 0.5873918981,
        },
        {
            value: 16,
            percentage: 0.1510013655,
            percentile: 0.6871870733,
        },
        {
            value: 17,
            percentage: 0.1255120619,
            percentile: 0.8381884388,
        },
        {
            value: 18,
            percentage: 0.0362994993,
            percentile: 0.9637005007,
        },
    ],
    total: [
        {
            value: 39,
            percentage: 0.0018206645,
            percentile: 0,
        },
        {
            value: 40,
            percentage: 0.0026172053,
            percentile: 0.0018206645,
        },
        {
            value: 41,
            percentage: 0.0047792444,
            percentile: 0.0044378698,
        },
        {
            value: 42,
            percentage: 0.006827492,
            percentile: 0.0092171142,
        },
        {
            value: 43,
            percentage: 0.0112653619,
            percentile: 0.0160446062,
        },
        {
            value: 44,
            percentage: 0.0164997724,
            percentile: 0.0273099681,
        },
        {
            value: 45,
            percentage: 0.0237824306,
            percentile: 0.0438097405,
        },
        {
            value: 46,
            percentage: 0.0333409194,
            percentile: 0.0675921711,
        },
        {
            value: 47,
            percentage: 0.0414201183,
            percentile: 0.1009330905,
        },
        {
            value: 48,
            percentage: 0.055416477,
            percentile: 0.1423532088,
        },
        {
            value: 49,
            percentage: 0.0639508421,
            percentile: 0.1977696858,
        },
        {
            value: 50,
            percentage: 0.0741920801,
            percentile: 0.2617205279,
        },
        {
            value: 51,
            percentage: 0.0862539827,
            percentile: 0.335912608,
        },
        {
            value: 52,
            percentage: 0.0870505234,
            percentile: 0.4221665907,
        },
        {
            value: 53,
            percentage: 0.0812471552,
            percentile: 0.5092171141,
        },
        {
            value: 54,
            percentage: 0.0769230769,
            percentile: 0.5904642693,
        },
        {
            value: 55,
            percentage: 0.0691852526,
            percentile: 0.6673873462,
        },
        {
            value: 56,
            percentage: 0.0675921711,
            percentile: 0.7365725988,
        },
        {
            value: 57,
            percentage: 0.0540509786,
            percentile: 0.8041647699,
        },
        {
            value: 58,
            percentage: 0.0472234866,
            percentile: 0.8582157485,
        },
        {
            value: 59,
            percentage: 0.0342512517,
            percentile: 0.9054392351,
        },
        {
            value: 60,
            percentage: 0.021961766,
            percentile: 0.9396904868,
        },
        {
            value: 61,
            percentage: 0.0158170232,
            percentile: 0.9616522528,
        },
        {
            value: 62,
            percentage: 0.0098998635,
            percentile: 0.977469276,
        },
        {
            value: 63,
            percentage: 0.0053482021,
            percentile: 0.9873691395,
        },
        {
            value: 64,
            percentage: 0.0040964952,
            percentile: 0.9927173416,
        },
        {
            value: 65,
            percentage: 0.0018206645,
            percentile: 0.9968138368,
        },
        {
            value: 66,
            percentage: 0.0012517069,
            percentile: 0.9986345013,
        },
        {
            value: 67,
            percentage: 0.0001137915,
            percentile: 0.9998862082,
        },
        {
            value: 68,
            percentage: 0.0001137915,
            percentile: 0.9998862082,
        },
    ],
};
